<template>
  <div class="newsletter-container">
    <v-container
    >
      <v-row 
        justify="center"
        align-self="center"
        class="mx-0 my-0 py-0 px-0"
      >
        <v-col 
          cols="12" 
          md="9" 
          lg="4" 
          xl="4"
          :class="`${$vuetify.breakpoint.mobile ? '' : 'contenedor'}`"
        >
          <h2 class="white--text">Email newsletter</h2>
          <h4 class="white--text">Suscríbete para recibir información</h4>
        </v-col>
        <v-col
          class="text-right"
          cols="12"
          md="9"
          lg="6"
          xl="4"
          :class="`${$vuetify.breakpoint.mobile ? '' : 'formulario'}`"
        >
          <v-form
            ref="newsform"
            v-model="valid"
            lazy-validation
          >
          
            <v-text-field
              v-model="email"
              solo
              placeholder="Correo electrónico"
              :rules="[rules.required, rules.email]"
            >
              <template v-slot:append>
                <v-btn
                  color="primary"
                  :loading="loading"
                  @click="submitEmail"
                >
                  Suscribir
                </v-btn>
              </template>
            </v-text-field>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from '@/mixins/formsValidations.js'
import { ApiAuth } from '../../api_utils/axios-base'
export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      email: null,
      loading: false
    }
  },
  methods: {
    async  submitEmail() {
      this.loading = true
      if (this.$refs.newsform.validate()) {
        await this.postNewsletter(this.email)
        this.email = null
        this.$refs.newsform.reset()
      }
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    async postNewsletter(email) {
      try {
        const response = await ApiAuth.post('/personas/newsletter', { email: email })
        const responseData = await response.data
      } catch (error) {
        console.error('Error al intentar enviar correo al newsletter', error);
      }
    }
  }
}
</script>

<style scoped>
  .contenedor{
  margin-top: 3%;

  }
  .formulario{
   margin-top: 4%;
   max-width: 43%;
  }
</style>